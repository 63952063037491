import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { PortalAppBar } from "./app-bar.component";
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import SidebarNavigation from './sidebar.component';

const drawerWidth = 240;

export const Navigation = () => {
    return (
        <div>
        <CssBaseline />
        <PortalAppBar />
        <Drawer
            variant="permanent"
            sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <SidebarNavigation />
        </Box>
      </Drawer>
      </div>
    )
}
