import React from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Navigation } from "./components/navigation/navigation.component";
import { ReportComponent } from "./components/report/report.component";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LidarComponent } from "./components/lidar/lidar.component";
import { ImageryComponent } from "./components/imagery/imagery.component";
import { WeatherComponent } from "./components/weather/weather.component";
import { ParcelComponent } from "./components/parcels/parcel.component";
import { HomeComponent } from "./components/home.component";

function App() {
  return (
    <Router>
      <div>
        <Box sx={{ display: 'flex'}}>
          <Navigation />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }} width={window.innerWidth} height={window.innerHeight - 50}>
            <Toolbar />
            <Routes>
              <Route path="/" element={<HomeComponent />} />
              <Route path="/lidar" element={<LidarComponent />} />
              <Route path="/imagery" element={<ImageryComponent />} />
              <Route path="/weather" element={<WeatherComponent />} />
              <Route path="/report" element={<ReportComponent />} />
              <Route path="/parcels" element={<ParcelComponent />} />
            </Routes>
          </Box>
        </Box>
      </div>
    </Router>
  );
}

export default App;
