import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import ForwardIcon from '@mui/icons-material/Forward';
import FormControl from '@mui/material/FormControl';
import { Stack } from '@mui/material';

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(0),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
      border: '1px solid',
      borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
      fontSize: 16,
      width: 'auto',
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));

export const UploadReportButton = ({ onFileChange, handleSubmit}) => {
    return (
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1 },
            }}
            display={'flex'}
            autoComplete="off"
            onSubmit={handleSubmit}>
                <FormControl variant="standard" margin="normal" required={true}>
                  <Stack direction={'row'} spacing={2}>
                  <BootstrapInput defaultValue="" id="bootstrap-input" type='file' onChange={onFileChange} />
                    <Button variant='contained' type='submit' endIcon={<ForwardIcon />}>
                        Analyze Coverage
                    </Button>
                  </Stack>
                </FormControl>
        </Box>
    )
}
