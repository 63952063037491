import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import GiteIcon from '@mui/icons-material/Gite';
import ImageIcon from '@mui/icons-material/Image';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import FenceIcon from '@mui/icons-material/Fence';
import HomeIcon from '@mui/icons-material/Home';
import { useLocation } from 'react-router-dom';

export const SidebarNavigation = () => {
  const location = useLocation();
    return (
        <div>
            <List>
              <ListItem key='Home' disablePadding>
                <ListItemButton component={Link} to="/" selected={location.pathname === "/" ? true : false}>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary='Home' />
                </ListItemButton>
              </ListItem>
              <ListItem key='Coverage Analysis' disablePadding>
                  <ListItemButton component={Link} to="/report" selected={location.pathname === "/report" ? true : false}>
                    <ListItemIcon>
                      <ImageSearchIcon />
                    </ListItemIcon>
                    <ListItemText primary='Coverage Analysis' />
                  </ListItemButton>
              </ListItem>
              <ListItem key='LIDAR' disablePadding>
                <ListItemButton component={Link} to="/lidar" selected={location.pathname === "/lidar" ? true : false}>
                  <ListItemIcon>
                    <GiteIcon />
                  </ListItemIcon>
                  <ListItemText primary='LIDAR' />
                </ListItemButton>
              </ListItem>
              {/* <ListItem key='Imagery' disablePadding>
                <ListItemButton component={Link} to="/imagery" selected={location.pathname === "/imagery" ? true : false}>
                  <ListItemIcon>
                    <ImageIcon />
                  </ListItemIcon>
                  <ListItemText primary='Imagery' />
                </ListItemButton>
              </ListItem>
              <ListItem key='Weather' disablePadding>
                <ListItemButton component={Link} to="/weather" selected={location.pathname === "/weather" ? true : false}>
                  <ListItemIcon>
                    <ThunderstormIcon />
                  </ListItemIcon>
                  <ListItemText primary='Weather' />
                </ListItemButton>
              </ListItem>
              <ListItem key='Parcels' disablePadding>
                <ListItemButton component={Link} to="/parcels" selected={location.pathname === "/parcels" ? true : false}>
                  <ListItemIcon>
                    <FenceIcon />
                  </ListItemIcon>
                  <ListItemText primary='Parcels' />
                </ListItemButton>
              </ListItem> */}
          </List>
        </div>
    )
}

export default SidebarNavigation;
