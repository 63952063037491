import Box from '@mui/material/Box';

export const HomeComponent = () => {
    return (
        <Box>
            <h1>Welcome to the Geo Portal</h1>
            <Box
              height={100}
              width={400}
              my={2}
              display="flex"
              alignItems="center"
              gap={2}
              p={1}
            >
                This website is for internal use only. We hope you appreciate the art as we keep improving the Geo Portal!
            </Box>
            <img src='./images/geo-portal-2.jpeg' alt='geo-portal' height={400} width={400} />
        </Box>
    );
}
