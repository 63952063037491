import React, { useEffect, useState } from 'react';
import { CheckCoverage } from '../../services/coverage-service';
import { CoverageSummary } from './report-coverage-summary';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
    { id: 'address', label: 'Address', minWidth: 180 },
    { id: 'lat', label: 'Lat' },
    { id: 'lng', label: 'Lng' },
    { id: 'googleHigh', label: 'Google High' },
    { id: 'googleMedium', label: 'Google Medium' },
    { id: 'nearmap', label: 'Nearmap' },
    { id: 'vexcelUltra', label: 'Vexcel Ultra' },
    { id: 'vexcelHigh', label: 'Vexcel High' },
    { id: 'eagleView', label: 'EagleView' },
    { id: 'auroraLidar', label: 'Aurora LiDAR' },
]

export const ReportCoverage = (props) => {
    const [result, setResult] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    useEffect(() => {
        async function getCoverageData() {
            setIsLoading(true);
            const coverageResult = await CheckCoverage(props.coverage);
            setResult(coverageResult);
            setIsLoading(false);
        }
        getCoverageData();
    },[props.coverage]);

    if (!props.coverage || (!result && !isLoading)) {
        return null;
    }
    if (isLoading) {
        return (
            <Box
                my={4}
                sx={{
                    '& > :not(style)': { m: 1 },
                    display: 'flex',
                }}
                >
                <CircularProgress />
            </Box>
        )
    }
    return (
        <Box>
            <CoverageSummary addresses={result} />
            <Paper sx={{ width: '90%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 1440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) =>{
                                return (
                                    <TableRow hover tabIndex={-1} key={row.address}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.id === 'address'
                                                    ? value
                                                    : resultCell(value)}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={result.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

const resultCell = (result) => {
    if (result === undefined) {
        return <span>&#x2753;</span>;
    }
    return result ? <span>&#x2705;</span> : <span>&#x274c;</span>;
}

const AddressCoverage = ({address}) => {
    return (
        <tr>
            <td>{address.address}</td>
            <td>{address.lat}</td>
            <td>{address.lng}</td>
            <td>{resultCell(address.googleHigh)}</td>
            <td>{resultCell(address.googleMedium)}</td>
            <td>{resultCell(address.nearmap)}</td>
            <td>{resultCell(address.vexcelUltra)}</td>
            <td>{resultCell(address.vexcelHigh)}</td>
            <td>{resultCell(address.eagleView)}</td>
            <td>{resultCell(address.auroraLidar)}</td>
        </tr>
    )
}
