import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';

const rows = [
    { id: 'googleHigh', label: 'Google High Coverage' },
    { id: 'googleMedium', label: 'Google Medium Coverage' },
    { id: 'nearmap', label: 'Nearmap Coverage' },
    { id: 'vexcelUltra', label: 'Vexcel Ultra Coverage' },
    { id: 'vexcelHigh', label: 'Vexcel High Coverage' },
    { id: 'eagleView', label: 'EagleView Coverage' },
    { id: 'auroraLidar', label: 'Aurora LIDAR Coverage' },
]

export const CoverageSummary = ({addresses}) => {
    const getPercentage = (x, y) => {
        return Number(x/y).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2});
    }
    return (
        <Box my={4} display='block'>
            <h1>Coverage Summary</h1>
            <TableContainer component={Paper} sx={{ maxWidth: 350}}>
                <Table sx={{ minWidth: 200 }} size="small" aria-label="a dense table">
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.label}
                                </TableCell>
                                <TableCell align="right">{ itemCounter(addresses, row.id) }/{ addresses.length }</TableCell>
                                <TableCell align="left">{ getPercentage(itemCounter(addresses, row.id),addresses.length) }</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

const itemCounter = (value, index) => {
    return value.filter((x) => x[index]).length;
};

