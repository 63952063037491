import Box from '@mui/material/Box';

export const ParcelComponent = () => {
    return (
        <Box>
            <h1>Parcel Viewer</h1>
            <img src='./images/parcels.jpeg' alt='parcels' height={650} width={1200} />
        </Box>
    );
}
