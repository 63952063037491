
export const CheckCoverage = async (addresses) => {
    if (!addresses) return [];
    try {
        const response = await fetch(process.env.REACT_APP_FUNCTION_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({addresses: addresses}),
            mode: "cors",
        });

        console.log(response);
        const result = await response.json();
        console.log(result.coverage);
        return result.coverage;
    }
    catch (error) {
        console.error(error);
    }
}
