import React, { useState } from 'react';
import { ReportCoverage } from './report-coverage.component';
import { UploadReportButton } from './upload-report-button.component';
import Box from '@mui/material/Box';
import FileOpenIcon from '@mui/icons-material/FileOpen';

export const ReportComponent = () => {
    const [file, setFile] = useState();
    const [coverage, setCoverage] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            const lines = reader.result.split('\n');
            if (lines.length > 100) {
                alert('File contains more than 100 lines. Please select a file with less than 100 lines.');
                return;
            }
            setCoverage(lines);
        }
        reader.readAsText(file);
        }
    };

    const updateUploadedFiles = (event) => {
        setFile(event.target.files[0]);
    };

    return (
        <div>
            <Box
                width={900}
                my={2}
                alignItems="center"
                gap={2}
                p={1}
                marginLeft={8}
            >
                <h1>Geodata Coverage Checker</h1>
                <p>Internal resource to enable Aurorans to understand the coverage available for any given set of addresses.</p>
                <Box
                    my={2}
                    gap={2}
                    p={2}
                    sx={{ border: '1px solid black' }}
                >
                    <h2>Instructions</h2>
                    <p>
                        To check the coverage for a set of addresses, upload a CSV or TXT file containing the addresses.
                        The file should be formatted to have one address per line. <br />
                        See this example file for reference <a href='./files/sample.csv'><FileOpenIcon /></a>
                    </p>
                </Box>
                <UploadReportButton onFileChange={(e) => updateUploadedFiles(e)} handleSubmit={(e) => handleSubmit(e)} />
                <ReportCoverage coverage={coverage} />
            </Box>
      </div>
    )
}
