import React from "react";

export const ImageryComponent = () => {
    return (
        <div className='embed-container'>
            <iframe
                src='https://aurorasolar-lidar.s3.amazonaws.com/embedded-map-sunroof.html'
                width={window.innerWidth - 300}
                height={window.innerHeight - 100}
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                tabIndex="0"
            />
        </div>
    );
}
