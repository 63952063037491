import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PublicIcon from '@mui/icons-material/Public';

export const PortalAppBar = () => {
    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <PublicIcon />
          <Typography variant="h6" noWrap component="div" padding={1}>
            Geo Portal
          </Typography>
        </Toolbar>
      </AppBar>
    )
}
